.wizard {
  margin: 20px auto;
  background: #fff;

  .nav-tabs {
    position: relative;
    margin: 40px auto;
    margin-bottom: 0;
    border-bottom-color: #e0e0e0;
  }

  > div.wizard-inner {
    position: relative;
  }
}

.connecting-line {
  height: 2px;
  background: #e0e0e0;
  position: absolute;
  width: 80%;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 50%;
  z-index: 1;
}

.wizard .nav-tabs > li.active > a {
  color: #555555;
  cursor: default;
  border: 0;
  border-bottom-color: transparent;

  &:hover, &:focus {
    color: #555555;
    cursor: default;
    border: 0;
    border-bottom-color: transparent;
  }
}

span.round-tab {
  width: 70px;
  height: 70px;
  line-height: 70px;
  display: inline-block;
  border-radius: 100px;
  background: #fff;
  border: 2px solid #e0e0e0;
  z-index: 2;
  position: absolute;
  left: 0;
  text-align: center;
  font-size: 25px;

  i {
    color: #555555;
  }
}

.wizard li.active span.round-tab {
  background: #fff;
  border: 2px solid #5bc0de;

  i {
    color: #5bc0de;
  }
}

span.round-tab:hover {
  color: #333;
  border: 2px solid #333;
}

.wizard {
  .nav-tabs > li {
    width: 25%;
  }

  li {
    &:after {
      content: " ";
      position: absolute;
      left: 46%;
      opacity: 0;
      margin: 0 auto;
      bottom: 0px;
      border: 5px solid transparent;
      border-bottom-color: #5bc0de;
      transition: 0.1s ease-in-out;
    }

    &.active:after {
      content: " ";
      position: absolute;
      left: 46%;
      opacity: 1;
      margin: 0 auto;
      bottom: 0px;
      border: 10px solid transparent;
      border-bottom-color: #5bc0de;
    }
  }

  .nav-tabs > li a {
    width: 70px;
    height: 70px;
    margin: 20px auto;
    border-radius: 100%;
    padding: 0;

    &:hover {
      background: transparent;
    }
  }

  .tab-pane {
    position: relative;
    padding-top: 50px;
  }

  h3 {
    margin-top: 0;
  }
}

@media (max-width: 585px) {
  .wizard {
    width: 90%;
    height: auto !important;
  }

  span.round-tab {
    font-size: 16px;
    width: 50px;
    height: 50px;
    line-height: 50px;
  }

  .wizard {
    .nav-tabs > li a {
      width: 50px;
      height: 50px;
      line-height: 50px;
    }

    li.active:after {
      content: " ";
      position: absolute;
      left: 35%;
    }
  }
}
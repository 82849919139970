/*
 *  Document   : main.css
 *  Author     : pixelcave
 *  Description: The main stylesheet of the template
 *
 *  Structure (with shortcodes):
 *      (#m01mls) MAIN LAYOUT
 *      (#m02hds) HEADER
 *      (#m03sns) SIDEBAR/NAVIGATION
 *      (#m04mcs) MAIN CONTENT
 *      (#m05pgs) PAGES
 *      (#m06bos) BOOTSTRAP OVERWRITE/EXTEND STYLES
 *      (#m07hes) HELPERS
 *      (#m08ths) THEMES
 *      (#m09res) RESPONSIVE
 */

/*
=================================================================
(#m01mls) MAIN LAYOUT
=================================================================
*/

/* Include Lato font from Google Web Fonts */
@import url('http://fonts.googleapis.com/css?family=Lato:300,300italic,400,400italic,500,500italic,600,600italic,700,700italic');

/* Preloader */

#page-wrapper.page-loading {
  overflow: hidden;

  .preloader {
    display: block;
  }

  #page-container {
    opacity: 0;
  }
}

.preloader {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 99999;

  .inner {
    position: fixed;
    top: 20%;
    left: 50%;
    margin: 0 0 0 -50px;
    width: 100px;
    height: 100px;
    text-align: center;
  }
}

.preloader-spinner {
  width: 40px;
  height: 40px;
  margin: 0 auto;
  background-color: #cccccc;
  -webkit-animation: rotateplane .8s infinite ease-in-out;
  animation: rotateplane .8s infinite ease-in-out;
}

@-webkit-keyframes rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }

  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }

  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}

@keyframes rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }

  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }

  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

/* Main Structure */

body {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #454e59;
  background-color: #454e59;
}

#login-container {
  position: absolute;
  width: 300px;
  top: 30px;
  left: 50%;
  margin-left: -150px;
  z-index: 1000;
}

#page-container, #main-container {
  min-width: 320px;
}

#page-container {
  opacity: 1;
  width: 100%;
  padding: 0;
  margin: 0 auto;
  overflow-x: hidden;
  -webkit-transition: background-color .2s ease-out, opacity .3s ease-out;
  transition: background-color .2s ease-out, opacity .3s ease-out;
}

#page-content {
  padding: 10px 5px 1px;
  background-color: #ebeef2;
}

#page-content-sidebar {
  padding: 10px;
  margin: -10px -5px 10px;
  background-color: #ffffff;
  border-bottom: 2px solid #dae0e8;
}

#page-container {
  background-color: #454e59;
}

#sidebar {
  background-color: #454e59;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

#sidebar-alt, #sidebar-alt-close, #main-container {
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

header.navbar {
  &.navbar-fixed-top, &.navbar-fixed-bottom {
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out;
  }
}

#sidebar, #sidebar-alt, #sidebar-alt-close {
  z-index: 1029;
}

/* Alternative Sidebar */

#sidebar-alt, #sidebar-alt-close {
  background: rgba(69, 78, 89, 0.9);
}

#sidebar-alt {
  position: fixed;
  top: 50px;
  right: -220px;
  bottom: 0;
  width: 220px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

#page-container.header-fixed-bottom #sidebar-alt {
  top: 0;
  bottom: 50px;
}

#sidebar-alt-close {
  display: inline-block;
  position: fixed;
  right: -30px;
  top: 50px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  opacity: 0;
}

#page-container {
  &.header-fixed-top #sidebar-alt-close, &.header-fixed-bottom #sidebar-alt-close {
    display: none !important;
  }

  &.sidebar-alt-visible-xs {
    #sidebar-alt {
      -webkit-transform: translateX(-220px);
      transform: translateX(-220px);
    }

    #sidebar-alt-close {
      -webkit-transform: translateX(-250px);
      transform: translateX(-250px);
      opacity: 1;
    }
  }
}

/* Sidebar + Static Layout */

#sidebar {
  position: absolute;
  overflow: hidden;
  width: 220px;
  left: -220px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

#page-container {
  &.sidebar-visible-xs {
    #sidebar, #main-container {
      -webkit-transform: translateX(220px);
      transform: translateX(220px);
    }
  }

  &.header-fixed-top #page-content {
    padding-top: 60px;
  }

  &.header-fixed-bottom #page-content {
    padding-bottom: 60px;
  }

  &.header-fixed-top #sidebar {
    position: fixed;
    top: 50px;
    bottom: 0;
  }

  &.header-fixed-bottom #sidebar {
    position: fixed;
    top: 0;
    bottom: 50px;
  }

  &.header-fixed-top.sidebar-visible-xs #main-container, &.header-fixed-bottom.sidebar-visible-xs #main-container {
    -webkit-transform: none;
    transform: none;
  }

  &.header-fixed-top #sidebar-extra-info, &.header-fixed-bottom #sidebar-extra-info {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &.header-fixed-top #sidebar-brand, &.header-fixed-bottom #sidebar-brand {
    display: none;
  }

  &.fixed-width {
    position: relative;
    max-width: 1280px;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    #sidebar-alt, #sidebar-alt-close {
      background: rgba(25, 25, 25, 0.8) !important;
    }
  }
}

/* Sidebar + Fixed Layout */

/* Fixed Width Layout */

/*
=================================================================
(#m02hds) HEADER
=================================================================
*/

header {
  &.navbar-default, &.navbar-inverse {
    padding: 0;
    margin: 0;
    min-width: 320px;
    border: none;
  }
}

/* Header Nav */

.navbar-default .navbar-nav > li > a {
  color: #454e59;
}

.navbar-inverse .navbar-nav > li > a {
  color: #ffffff;
}

.navbar-default .navbar-nav > {
  li > a {
    &:hover, &:focus {
      color: #ffffff;
      background-color: #5ccdde;
    }
  }

  .active > a {
    color: #ffffff;
    background-color: #5ccdde;

    &:hover, &:focus {
      color: #ffffff;
      background-color: #5ccdde;
    }
  }

  .open > a {
    color: #ffffff;
    background-color: #5ccdde;

    &:hover, &:focus {
      color: #ffffff;
      background-color: #5ccdde;
    }
  }
}

.navbar-inverse {
  .navbar-nav > {
    li > a {
      &:hover, &:focus {
        color: #ffffff;
        background-color: #5ccdde;
      }
    }

    .active > a {
      color: #ffffff;
      background-color: #5ccdde;

      &:hover, &:focus {
        color: #ffffff;
        background-color: #5ccdde;
      }
    }

    .open > a {
      color: #ffffff;
      background-color: #5ccdde;

      &:hover, &:focus {
        color: #ffffff;
        background-color: #5ccdde;
      }
    }
  }

  .collapse.in .navbar-nav .open .dropdown-menu > li > a {
    color: #dddddd;
  }
}

.nav.navbar-nav-custom {
  float: left;
  margin: 0;

  > li {
    min-height: 50px;
    float: left;

    > a {
      min-width: 50px;
      padding: 5px 7px;
      line-height: 40px;
      text-align: center;
      color: #454e59;
      position: relative;

      .gi, .hi, .si, .fi {
        margin-top: -3px;
      }

      .label-indicator {
        position: absolute;
        top: 4px;
        right: 4px;
        min-width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        border-radius: 50%;
        font-weight: 600;
        border-radius: 10px;
      }
    }
  }
}

.navbar-inverse .nav.navbar-nav-custom > li > a {
  color: #ffffff;
}

.nav.navbar-nav-custom > li {
  &.open > a {
    background-color: #5ccdde;
    color: #ffffff;
  }

  > a {
    &:hover, &:focus {
      background-color: #5ccdde;
      color: #ffffff;
    }
  }
}

.navbar-inverse .nav.navbar-nav-custom > li {
  &.open > a {
    background: rgba(0, 0, 0, 0.15);
    color: #ffffff;
  }

  > a {
    &:hover, &:focus {
      background: rgba(0, 0, 0, 0.15);
      color: #ffffff;
    }
  }
}

.nav.navbar-nav-custom {
  > li > a > img {
    width: 40px;
    height: 40px;
    border-width: 2px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.75);
    border-radius: 20px;
    vertical-align: top;
  }

  .dropdown-menu {
    margin-top: 0;
  }
}

/* Header Search */

.navbar-form {
  margin: 7px 0;
}

.collapsing .navbar-form, .collapse.in .navbar-form {
  margin-left: -15px;
  margin-right: -15px;
}

.collapsing .navbar-form .form-group, .collapse.in .navbar-form .form-group {
  margin-bottom: 0;
}

.navbar-form-custom {
  margin: 0;
  padding: 5px 8px;
  width: 140px;

  .form-control {
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;
    height: 40px;
    border: none;
    background: #ffffff;
    border-radius: 20px;
    opacity: .5;
  }
}

.navbar-default .navbar-form-custom .form-control {
  background: #eeeeee;
}

.navbar-form-custom .form-control {
  &:hover, &:focus {
    opacity: 1;
  }
}

/*
=================================================================
(#m03sns) SIDEBAR/NAVIGATION
=================================================================
*/

/* Sidebar Content */

.sidebar-content {
  width: 220px;
  color: #acb5bf;
}

.sidebar-section {
  padding: 10px 15px;
}

#sidebar-extra-info {
  padding: 7px 15px 10px;
}

/* Sidebar Title */

.sidebar-title {
  height: 50px;
  line-height: 50px;
  padding: 0 10px;
  margin: 0;
  font-weight: 300;
  font-size: 18px;
  display: block;
  color: #ffffff;
  background-color: #5ccdde;
  -webkit-transition: background .2s ease-out;
  transition: background .2s ease-out;
}

a.sidebar-title {
  &:hover, &:focus {
    background: rgba(0, 0, 0, 0.15);
    color: #ffffff;
    text-decoration: none;
  }
}

.sidebar-title i {
  width: 18px;
  margin-right: 8px;
  opacity: .75;
}

/* Sidebar Navigation */

.sidebar-nav {
  list-style: none;
  margin: 0;
  padding: 10px 0 0;
}

.sidebar-separator {
  padding: 5px 0;
  color: #ffffff;
  text-align: center;

  i {
    opacity: .1;
  }
}

#sidebar-toggle-mini {
  display: none;
}

.sidebar-nav-ripple {
  display: block;
  position: absolute;
  background: rgba(92, 205, 222, 0.3);
  border-radius: 100%;
  -webkit-transform: scale(0);
  transform: scale(0);

  &.animate {
    -webkit-animation: ripple 1s ease-out;
    animation: ripple 1s ease-out;
  }
}

@-webkit-keyframes ripple {
  100% {
    opacity: 0;
    -webkit-transform: scale(3);
  }
}

@keyframes ripple {
  100% {
    opacity: 0;
    -webkit-transform: scale(3);
    transform: scale(3);
  }
}

.sidebar-nav {
  a {
    display: block;
    color: #acb5bf;
    padding: 0 10px;
    min-height: 40px;
    line-height: 40px;
    overflow: hidden;
    position: relative;

    &:hover, &:focus, &.active, &.open {
      color: #ffffff;
      text-decoration: none;
    }
  }

  li.active > a {
    color: #ffffff;
    text-decoration: none;
  }

  a.open, li.active > a {
    background: rgba(0, 0, 0, 0.2);
  }

  a {
    &.active {
      background: #ffffff;
      color: #454e59;
      border-right: 4px solid #5ccdde;
    }

    > {
      .sidebar-nav-icon {
        margin-right: 10px;
        display: inline-block;
        opacity: .5;
        width: 18px;
        font-size: 14px;
        text-align: center;
      }

      .sidebar-nav-indicator {
        display: inline-block;
        opacity: .5;
        width: 18px;
        font-size: 14px;
        text-align: center;
        float: right;
        line-height: inherit;
        margin-left: 4px;
        font-size: 10px;
        -webkit-transition: -webkit-transform .15s ease-out;
        transition: transform .15s ease-out;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
      }
    }

    &:hover {
      opacity: 1;

      > {
        .sidebar-nav-icon, .sidebar-nav-indicator {
          opacity: 1;
        }
      }
    }

    &.active {
      opacity: 1;

      > {
        .sidebar-nav-icon, .sidebar-nav-indicator {
          opacity: 1;
        }
      }
    }

    &.open {
      opacity: 1;

      > {
        .sidebar-nav-icon, .sidebar-nav-indicator {
          opacity: 1;
        }
      }
    }
  }

  li.active > a {
    opacity: 1;

    > {
      .sidebar-nav-icon, .sidebar-nav-indicator {
        opacity: 1;
      }
    }
  }

  a {
    &.active > .sidebar-nav-indicator, &.open > .sidebar-nav-indicator {
      -webkit-transform: rotate(-90deg) rotateZ(0deg);
      transform: rotate(-90deg) rotateZ(0deg);
    }
  }

  li.active > a > .sidebar-nav-indicator {
    -webkit-transform: rotate(-90deg) rotateZ(0deg);
    transform: rotate(-90deg) rotateZ(0deg);
  }

  ul {
    display: none;
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
    -webkit-transition: background .25s ease-out;
    transition: background .25s ease-out;
  }

  .active > ul, .open + ul {
    display: block;
    background: rgba(255, 255, 255, 0.1);
  }

  ul {
    a {
      margin: 0 0 0 19px;
      padding-left: 19px;
      border-left: 1px solid #454e59;
      min-height: 34px;
      line-height: 34px;
      font-weight: normal;

      &.open {
        background: none;
      }
    }

    li.active > a {
      background: none;
    }

    ul {
      margin: 0 0 0 19px;

      a {
        margin: 0;
        padding-left: 19px;
        font-size: 13px;
        min-height: 32px;
        line-height: 32px;
      }
    }

    .active > ul, .open + ul {
      background-color: #454e59;
    }
  }
}

/* Sidebar Light Mode */

#page-container.sidebar-light {
  background-color: #ffffff;

  .content-header {
    background: rgba(255, 255, 255, 0.75);
  }

  #page-content-sidebar {
    padding-left: 9px;
  }

  .sidebar-themes li.active a {
    border: 2px solid #333333;
  }

  #sidebar {
    background-color: #ffffff;

    .sidebar-content {
      color: #999999;
    }

    .sidebar-separator {
      color: #000000;
    }

    .sidebar-nav {
      a {
        color: #7b7b7b;

        &:hover, &:focus, &.active, &.open {
          color: #4b4b4b;
        }
      }

      li.active > a {
        color: #4b4b4b;
      }

      a.open, li.active > a {
        background: rgba(0, 0, 0, 0.1);
      }

      a.active {
        background-color: #f9f9f9;
      }

      .active > ul, .open + ul {
        background: rgba(0, 0, 0, 0.04);
      }

      ul a {
        border-left-color: #cbcbcb;
      }
    }
  }
}

/* Sidebar Color Themes */

.sidebar-themes {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
  opacity: .35;
  transition: opacity .15s ease-out;

  &:hover {
    opacity: 1;
  }

  li {
    float: left;
    margin: 0 6px 10px 0;

    a {
      position: relative;
      display: block;
      width: 21px;
      height: 21px;
      overflow: hidden;

      &:hover {
        border: 2px solid rgba(255, 255, 255, 0.66);
      }
    }

    &.active a {
      border: 2px solid #ffffff;
    }
  }

  .section-header, .section-side, .section-content {
    position: absolute;
    display: block;
  }

  .section-side {
    top: 8px;
    right: 8px;
    bottom: 0;
    left: 0;
    background-color: #f9f9f9;
  }

  .section-header {
    top: 0;
    right: 0;
    bottom: 9px;
    left: 9px;
    background-color: #ffffff;
  }

  .section-content {
    top: 8px;
    right: 0;
    bottom: 0;
    left: 9px;
    background-color: #dddddd;
  }
}

/*
=================================================================
(#m04mcs) MAIN CONTENT
=================================================================
*/

/* Content Header */

.content-header {
  background-color: #ffffff;
  border-bottom: 2px solid #dae0e8;
  margin: -10px -5px 10px;
  padding: 0 15px;

  h1, h2 {
    margin: 5px 0;
    line-height: 28px;
  }

  h1 {
    font-weight: 500;
    font-size: 24px;
  }

  h2 {
    font-size: 18px;
  }

  small {
    font-size: 14px;
    font-weight: 400;
  }
}

.header-section {
  padding: 15px 0;
}

.content-header-media {
  position: relative;
  height: 260px;
  overflow: hidden;

  .header-section {
    z-index: 10;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-left: 15px;
    padding-right: 15px;
    color: #ffffff;
    background: rgba(0, 0, 0, 0.55);
  }

  > img {
    position: absolute;
    top: 0;
    left: 50%;
    width: 2560px;
    height: 260px;
    margin-left: -1280px;
  }

  small {
    color: #cccccc;
  }
}

/* Blocks */

.block {
  margin: 0 0 10px;
  padding: 20px 15px 1px;
  background-color: #ffffff;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  -webkit-box-shadow: 0 2px 0 rgba(218, 224, 232, 0.5);
  box-shadow: 0 2px 0 rgba(218, 224, 232, 0.5);

  .block {
    border: 1px solid #dae0e8;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &.full {
    padding: 20px 15px;
  }

  .block-content-full {
    margin: -20px -15px -1px;
  }

  .block-content-mini-padding {
    padding: 8px;
  }

  &.full .block-content-full {
    margin: -20px -15px;
  }
}

.block-title {
  margin: -20px -15px 20px;
  border-bottom: 2px solid #dae0e8;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background: rgba(218, 224, 232, 0.15);

  h1, h2, h3, h4, h5, h6 {
    display: inline-block;
    font-size: 14px;
    line-height: 1.4;
    margin: 0;
    padding: 10px 15px 9px;
    font-weight: 600;
    text-transform: uppercase;
  }

  h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
    font-size: 14px;
    font-weight: 600;
  }

  .nav-tabs {
    padding: 0;
    margin: 0;
    border-bottom: none;

    > li {
      > a {
        border: none;
        min-height: 40px;
        line-height: 40px;
        padding-top: 0;
        padding-bottom: 0;
        margin: 0;
        border-radius: 0;

        &:hover {
          background-color: #ffffff;
        }
      }

      &.active > a {
        border: none;
        background-color: #dae0e8;

        &:hover, &:focus {
          border: none;
          background-color: #dae0e8;
        }
      }
    }
  }
}

.block-options {
  padding: 2px 3px;
  height: 38px;

  .label {
    display: inline-block;
    padding: 6px;
    vertical-align: middle;
    font-size: 14px;
  }

  .dropdown-menu {
    margin-top: 3px;
  }

  .btn {
    padding-right: 8px;
    padding-left: 8px;
    min-width: 34px;
    text-align: center;
    color: #454e59;
    opacity: .4;
    background: transparent;
    border-color: transparent;
    -webkit-transition: opacity .15s ease-out;
    transition: opacity .15s ease-out;

    &:hover, &:active, &.active, &:focus {
      opacity: 1;
    }

    i {
      font-size: 14px;
    }
  }
}

.block-section {
  margin-bottom: 20px;
}

/* Widgets */

.widget {
  background-color: #ffffff;
  margin-bottom: 10px;
  position: relative;
  border-radius: 2px;

  > {
    .widget-content:first-child, .widget-image:first-child {
      border-top-right-radius: 2px;
      border-top-left-radius: 2px;
    }

    .widget-content:last-child {
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 2px;
    }
  }
}

a {
  &.widget, &.widget-content {
    display: block;
    -webkit-transition: all .1s ease-out;
    transition: all .1s ease-out;
  }

  &.widget {
    &:hover, &:focus {
      text-decoration: none;
      -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
      -webkit-transform: translateY(-2px);
      transform: translateY(-2px);
    }
  }

  &.widget-content {
    &:hover, &:focus {
      text-decoration: none;
      -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
      -webkit-transform: translateY(-2px);
      transform: translateY(-2px);
    }
  }

  &.widget:active, &.widget-content:active {
    text-decoration: none;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.widget-image {
  height: 310px;
  overflow: hidden;
  position: relative;

  > img {
    min-width: 787px;
  }

  &.widget-image-bottom img {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  &.widget-image-sm {
    height: 270px;
  }

  &.widget-image-xs {
    height: 175px;
  }

  > i {
    position: absolute;
    right: 30px;
    bottom: 30px;
    color: #ffffff;
    font-size: 70px;
    opacity: .5;
  }
}

.widget-image-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px 25px;
  background: rgba(0, 0, 0, 0.65);
}

.widget-content {
  padding: 15px;

  &.widget-content-mini {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  &.widget-content-full {
    padding: 0;
  }

  &.widget-content-full-top-bottom {
    padding-top: 0;
    padding-bottom: 0;
  }

  .pull-left {
    margin-right: 20px;
  }

  .pull-right {
    margin-left: 20px;
  }
}

a.widget-content {
  display: block;
  display: block;
}

.widget-heading {
  margin: 10px 0;
}

.widget-icon {
  display: inline-block;
  width: 64px;
  height: 64px;
  line-height: 60px;
  margin: 5px;
  font-size: 28px;
  text-align: center;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.05);

  &.pull-left {
    margin: 5px 5px 5px 0;
  }

  &.pull-right {
    margin: 5px 0 5px 5px;
  }

  .gi, .hi, .si, .fi {
    margin-top: -3px;
  }
}

/* Full Background Image */

img.full-bg {
  min-height: 100%;
  min-width: 1280px;
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;

  &.full-bg-bottom {
    top: auto;
    bottom: 0;
  }
}

@media screen and (max-width: 1280px) {
  img.full-bg {
    left: 50%;
    margin-left: -640px;
  }
}

/* Content which contains floats */

.content-float {
  .pull-left {
    margin: 0 20px 20px 0;
  }

  .pull-right {
    margin: 0 0 20px 20px;
  }
}

.content-float-hor {
  .pull-left {
    margin-right: 20px;
  }

  .pull-right {
    margin-left: 20px;
  }
}

/*
=================================================================
(#m05pgs) PAGES
=================================================================
*/

/* Calendar */

.calendar-events {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    color: #ffffff;
    font-weight: 600;
    margin-bottom: 6px;
    padding: 10px 12px;
    border-radius: 3px;
    background-color: #555555;
    opacity: .8;
    -webkit-box-shadow: inset 2px 0 rgba(0, 0, 0, 0.15);
    box-shadow: inset 2px 0 rgba(0, 0, 0, 0.15);

    i {
      margin-right: 10px;
    }

    &:hover {
      cursor: move;
      opacity: 1;
    }
  }
}

/* Gallery */

.gallery img {
  max-width: 100%;
}

a {
  &[data-toggle="lightbox-image"] {
    img {
      max-width: 100%;
    }

    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in;
  }

  &.gallery-link {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in;
  }

  &[data-toggle="lightbox-image"]:hover img {
    opacity: .75;
  }
}

.gallery-image-container {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}

.gallery-image-options {
  padding: 30px 0;
  text-align: center;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  -webkit-transform: scale(0.75);
  transform: scale(0.75);
  -webkit-transition: all .25s ease-out;
  transition: all .25s ease-out;

  &:hover, &:focus {
    text-decoration: none;
  }
}

.gallery-image-container:hover .gallery-image-options {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* Media Items */

.media-items {
  position: relative;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  background: rgba(255, 255, 255, 0.5);
  -webkit-transition: all .1s ease-out;
  transition: all .1s ease-out;

  &:hover {
    background: rgba(255, 255, 255, 1);
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);

    .media-items-options {
      display: block;
    }
  }

  .media-items-options {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    padding: 10px;
    display: none;
  }

  .media-items-content {
    padding: 10px 0;
  }
}

/* Charts */

.pie-chart .pie-avatar {
  position: absolute;
  top: 8px;
  left: 8px;
}

.chart-tooltip, .mini-chart-tooltip {
  position: absolute;
  display: none;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.75);
  padding: 4px 8px;
}

.chart-pie-label {
  font-size: 13px;
  text-align: center;
  padding: 4px 8px;
  color: #ffffff;
}

.mini-chart-tooltip {
  left: 0;
  top: 0;
  visibility: hidden;
}

.legend table td {
  padding: 3px 5px;
  font-size: 15px;
}

/* Error */

#error-container {
  padding: 30px;
  position: relative;

  h1 {
    font-size: 72px;
    margin-bottom: 30px;
  }

  h2 {
    margin-bottom: 50px;
  }
}

/* Timeline */

.timeline {
  position: relative;
}

.timeline-list {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    position: relative;
    margin: 0;
    padding: 0 0 20px;
  }
}

.timeline-icon {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  text-align: center;
  background-color: #eeeeee;
  border-radius: 3px;
  z-index: 500;
}

.timeline-time {
  padding: 0 80px 0 20px;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  border-bottom: 2px solid #eeeeee;
  margin-bottom: 15px;
  background-color: #f9f9f9;
}

.timeline-content {
  padding-left: 20px;
}

.block-content-full .timeline-content, .widget-content-full .timeline-content {
  padding-right: 20px;
}

/* Chat UI */

.chatui.chatui-window.widget {
  position: fixed;
  bottom: 0;
  right: 50%;
  width: 300px;
  margin-right: -150px;
  margin-bottom: 0;
  z-index: 1028;
}

#page-container.header-fixed-bottom .chatui.chatui-window {
  bottom: 50px;
}

.chatui {
  .chatui-action-close, .chatui-action-open {
    display: none;
  }

  &.chatui-window {
    .chatui-action-close {
      display: inline;
    }

    &.chatui-window-close {
      .chatui-action-open {
        display: inline;
      }

      .chatui-talk, .chatui-form, .chatui-action-close {
        display: none;
      }
    }
  }
}

.chatui-talk {
  height: 250px;
  overflow-y: auto;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  li {
    padding: 2px 0;
    margin-bottom: 5px;
  }

  .chatui-talk-msg {
    min-height: 40px;
    padding: 10px 10px 10px 46px;
    background-color: #f6f6f6;
    border-radius: 25px;
    position: relative;
    margin-right: 15%;

    &.chatui-talk-msg-right {
      margin-left: 15%;
      margin-right: 0;
    }
  }
}

.chatui-talk-msg .chatui-talk-msg-avatar {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 32px;
  height: 32px;
}

.chatui-form {
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid #eeeeee;

  .form-control {
    border-radius: 0;
    border: none;

    &:focus {
      border-radius: 0;
      border: none;
    }
  }
}

/* Tasks */

.task-list {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    position: relative;
    padding: 10px 40px 10px 15px;
    margin-bottom: 12px;
    background-color: #ffffff;
    border-radius: 3px;
    -webkit-transition: box-shadow .15s ease-out;
    transition: box-shadow .15s ease-out;

    &:hover {
      -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
    }

    &.in-1x {
      margin-left: 20px;
    }

    &.in-2x {
      margin-left: 40px;
    }

    > .task-close {
      position: absolute;
      top: 10px;
      right: 15px;
      opacity: 0;
      -webkit-transition: opacity .15s ease-out;
      transition: opacity .15s ease-out;
    }

    &:hover > .task-close {
      opacity: 1;
    }

    &.task-done {
      opacity: .5;

      > label {
        text-decoration: line-through;
      }

      &:hover {
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }
  }
}

/*
=================================================================
(#m06bos) BOOTSTRAP OVERWRITE/EXTEND STYLES
=================================================================
*/

/* Navbar */

.navbar {
  &.navbar-default.navbar-glass {
    background: rgba(255, 255, 255, 0.9);
  }

  &.navbar-inverse.navbar-glass {
    background: rgba(92, 205, 222, 0.9);
  }

  &.navbar-default {
    background: #ffffff;
    border-bottom: 1px solid #dae0e8;

    &.navbar-glass:hover {
      background: #ffffff;
      border-bottom: 1px solid #dae0e8;
    }
  }

  &.navbar-inverse {
    background: #5ccdde;

    &.navbar-glass:hover {
      background: #5ccdde;
    }
  }
}

.navbar-fixed-top, .navbar-fixed-bottom {
  border-width: 0;
}

/* Typography */

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
}

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small, .h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small, h1 .small, h2 .small, h3 .small, h4 .small, h5 .small, h6 .small, .h1 .small, .h2 .small, .h3 .small, .h4 .small, .h5 .small, .h6 .small {
  font-weight: 300;
  color: #879599;
}

h1, h2, h3 {
  margin-bottom: 15px;
}

.text-primary {
  color: #5ccdde;

  &:hover {
    color: #5ccdde;
  }
}

a {
  color: #5ccdde;

  &:hover, &:focus {
    color: #5ccdde;
  }

  &.text-primary {
    color: #5ccdde;

    &:hover, &:focus {
      color: #5ccdde;
    }
  }
}

.text-danger {
  color: #de815c;

  &:hover {
    color: #de815c;
  }
}

a.text-danger {
  color: #de815c;

  &:hover, &:focus {
    color: #de815c;
  }
}

.text-warning {
  color: #deb25c;

  &:hover {
    color: #deb25c;
  }
}

a.text-warning {
  color: #deb25c;

  &:hover, &:focus {
    color: #deb25c;
  }
}

.text-success {
  color: #afde5c;

  &:hover {
    color: #afde5c;
  }
}

a.text-success {
  color: #afde5c;

  &:hover, &:focus {
    color: #afde5c;
  }
}

.text-info {
  color: #5cafde;

  &:hover {
    color: #5cafde;
  }
}

a.text-info {
  color: #5cafde;

  &:hover, &:focus {
    color: #5cafde;
  }
}

.text-muted {
  color: #999999;

  &:hover {
    color: #999999;
  }
}

a.text-muted {
  color: #999999;

  &:hover, &:focus {
    color: #999999;
  }
}

.text-light {
  color: #ffffff;

  &:hover {
    color: #ffffff;
  }
}

a.text-light {
  color: #ffffff;

  &:hover, &:focus {
    color: #ffffff;
  }
}

.text-dark {
  color: #454e59;

  &:hover {
    color: #454e59;
  }
}

a.text-dark {
  color: #454e59;

  &:hover, &:focus {
    color: #454e59;
  }
}

.text-black {
  color: #000000;

  &:hover {
    color: #000000;
  }
}

a.text-black {
  color: #000000;

  &:hover, &:focus {
    color: #000000;
  }
}

.text-light-op {
  color: rgba(255, 255, 255, 0.8);

  &:hover {
    color: rgba(255, 255, 255, 0.8);
  }
}

a.text-light-op {
  color: rgba(255, 255, 255, 0.8);

  &:hover, &:focus {
    color: rgba(255, 255, 255, 0.8);
  }
}

.text-dark-op {
  color: rgba(0, 0, 0, 0.4);

  &:hover {
    color: rgba(0, 0, 0, 0.4);
  }
}

a.text-dark-op {
  color: rgba(0, 0, 0, 0.4);

  &:hover, &:focus {
    color: rgba(0, 0, 0, 0.4);
  }
}

b, strong {
  font-weight: 600;
}

ul, ol {
  padding-left: 30px;
}

p {
  line-height: 1.7;
}

.well {
  background-color: #f9f9f9;
  border: 1px solid #eeeeee;
}

.page-header {
  margin: 30px 0;
  padding: 10px 0;
  border-bottom: 1px solid #dddddd;
}

.sub-header {
  margin: 20px 0;
  padding: 10px 0;
  border-bottom: 1px dashed #dddddd;
}

.inner-sidebar-header {
  margin: 20px 0 10px;

  .pull-right, .pull-left {
    margin-top: -2px;
  }
}

blockquote {
  margin: 20px 0;
  padding: 30px 60px 30px 20px;
  position: relative;
  width: 100%;
  background-color: #f9f9f9;
  border-radius: 3px;

  &:before {
    display: block;
    content: "\201C";
    font-family: serif;
    font-size: 96px;
    position: absolute;
    right: 10px;
    top: -20px;
    color: #000000;
    opacity: .15;
  }

  &.pull-right:before {
    left: 10px;
    right: auto;
  }
}

article.article-story p {
  font-size: 18px;
  line-height: 1.8;
}

/* Forms */

fieldset legend {
  font-size: 18px;
  padding: 30px 0 10px;
  border-bottom: 2px solid #ebeef2;
}

input {
  &[type="file"] {
    padding-top: 7px;
  }

  &[type="text"].form-control, &[type="password"].form-control, &[type="email"].form-control {
    -webkit-appearance: none;
  }
}

textarea.form-control {
  -webkit-appearance: none;
}

.form-control {
  padding: 6px 8px;
  max-width: 100%;
  margin: 1px 0;
  color: #454e59;
  border-color: #dae0e8;
  border-radius: 3px;
}

input {
  &[type="text"].form-control.input-lg, &[type="password"].form-control.input-lg, &[type="email"].form-control.input-lg {
    height: 45px;
  }
}

.input-group {
  margin-top: 1px;
  margin-bottom: 1px;

  .form-control {
    margin-top: 0;
  }
}

.form-control:focus {
  border-color: #5ccdde;
}

.help-block {
  margin-top: 8px;
  color: #999999;
  font-weight: 400;
  font-style: italic;
}

.input-group-addon {
  min-width: 45px;
  text-align: center;
  background-color: #ffffff;
  border-color: #dae0e8;
}

.form-horizontal {
  .control-label {
    margin-bottom: 5px;
  }

  .control-label-fixed {
    padding-top: 5px;
    margin-bottom: 5px;
  }
}

.form-bordered {
  margin: -15px -15px -1px;
}

.modal-body .form-bordered {
  margin-bottom: -20px;
}

.form-bordered {
  fieldset legend {
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;
  }

  .form-group {
    margin: 0;
    border: none;
    padding: 15px;
    border-bottom: 1px dotted #ebeef2;

    &.form-actions {
      background-color: #f9f9f9;
      border-bottom: none;
    }
  }
}

.form-horizontal.form-bordered .form-group {
  padding-left: 0;
  padding-right: 0;
}

.form-bordered .help-block {
  margin-bottom: 0;
}

.has-success .form-control, .has-warning .form-control, .has-error .form-control {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.has-success {
  .help-block, .control-label, .input-group-addon, .checkbox, .checkbox-inline, .radio, .radio-inline {
    color: #afde5c;
  }

  .form-control, .input-group-addon {
    border-color: #afde5c;
    background-color: #ffffff;
  }

  .form-control:focus {
    border-color: #7fb364;
  }
}

.has-warning {
  .help-block, .control-label, .input-group-addon, .checkbox, .checkbox-inline, .radio, .radio-inline {
    color: #deb25c;
  }

  .form-control, .input-group-addon {
    border-color: #deb25c;
    background-color: #ffffff;
  }

  .form-control:focus {
    border-color: #e58f5a;
  }
}

.has-error {
  .help-block, .control-label, .input-group-addon, .checkbox, .checkbox-inline, .radio, .radio-inline {
    color: #de815c;
  }

  .form-control, .input-group-addon {
    border-color: #de815c;
    background-color: #ffffff;
  }

  .form-control:focus {
    border-color: #cc7872;
  }
}

.form-control.form-control-borderless {
  border: none;

  &:focus {
    border: none;
  }
}

/* Form Select Switches */

.switch {
  margin: 1px 0;
  position: relative;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;

  input {
    position: absolute;
    opacity: 0;
  }

  span {
    position: relative;
    display: inline-block;
    width: 54px;
    height: 28px;
    border-radius: 14px;
    background: rgba(0, 0, 0, 0.2);
    -webkit-transition: background-color .75s;
    transition: background-color .75s;

    &:after {
      content: "";
      position: absolute;
      left: 2px;
      top: 2px;
      bottom: 2px;
      width: 24px;
      background-color: #ffffff;
      border-radius: 12px;
      -webkit-transition: all .15s ease-in-out;
      transition: all .15s ease-in-out;
    }
  }

  input:checked + span {
    &:after {
      left: 27px;
      width: 24px;
      top: 2px;
      bottom: 2px;
    }

    background-color: #eeeeee;
  }
}

.switch-default input:checked + span {
  background-color: #dae0e8;
}

.switch-warning input:checked + span {
  background-color: #deb25c;
}

.switch-danger input:checked + span {
  background-color: #de815c;
}

.switch-info input:checked + span {
  background-color: #5cafde;
}

.switch-success input:checked + span {
  background-color: #afde5c;
}

.switch-primary input:checked + span {
  background-color: #5ccdde;
}

/* Form CSS Checkboxes */

.csscheckbox {
  margin: 1px 0;
  padding: 7px 0;
  position: relative;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;

  input {
    position: absolute;
    opacity: 0;
  }

  span {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    background: rgba(250, 250, 250, 0.25);
    border: 1px solid #dae0e8;

    &:hover {
      background: rgba(250, 250, 250, 0.75);
    }

    &:after {
      content: "";
      position: absolute;
      top: 3px;
      right: 3px;
      bottom: 3px;
      left: 3px;
      border-radius: 3px;
      background-color: #ffffff;
      opacity: 0;
      -webkit-transition: all .15s ease-out;
      transition: all .15s ease-out;
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
  }

  input:checked + span:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    background-color: #eeeeee;
  }
}

.csscheckbox-default input:checked + span:after {
  background-color: #dae0e8;
}

.csscheckbox-warning input:checked + span:after {
  background-color: #deb25c;
}

.csscheckbox-danger input:checked + span:after {
  background-color: #de815c;
}

.csscheckbox-info input:checked + span:after {
  background-color: #5cafde;
}

.csscheckbox-success input:checked + span:after {
  background-color: #afde5c;
}

.csscheckbox-primary input:checked + span:after {
  background-color: #5ccdde;
}

/* Tables */

.table {
  &.table-vcenter {
    th, td {
      vertical-align: middle;
    }
  }

  thead > tr > {
    th {
      font-size: 18px;
      font-weight: 600;

      > small {
        font-weight: 400;
        font-size: 75%;
      }

      padding-top: 15px;
      padding-bottom: 15px;
    }

    td {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  tfoot > tr > {
    th, td {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  tbody > tr > {
    th, td {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  &.table-condensed tbody > tr > {
    th, td {
      padding-top: 3px;
      padding-bottom: 3px;
    }
  }

  tfoot > tr > {
    th, td {
      background-color: #f9f9f9;
    }
  }
}

.table-borderless tbody > tr > {
  th, td {
    border-top-width: 0;
  }
}

.table {
  thead > tr > th, tbody > tr > th, tfoot > tr > th, thead > tr > td, tbody > tr > td, tfoot > tr > td, tbody + tbody {
    border-color: #ebeef2;
  }
}

.table-bordered {
  border-color: #ebeef2;

  > {
    thead > tr > th, tbody > tr > th, tfoot > tr > th, thead > tr > td, tbody > tr > td, tfoot > tr > td {
      border-color: #ebeef2;
    }
  }
}

.table-hover > tbody > tr:hover > {
  td, th {
    background-color: #ebeef2;
  }
}

.td-label {
  border-left-width: 3px;
  border-left-style: solid;
}

.td-label-danger {
  border-left-color: #de815c !important;
}

.td-label-warning {
  border-left-color: #deb25c !important;
}

.td-label-info {
  border-left-color: #5cafde !important;
}

.td-label-success {
  border-left-color: #afde5c !important;
}

.td-label-muted {
  border-left-color: #cccccc !important;
}

.table.table-pricing {
  background-color: #ffffff;
  text-align: center;
  border: 2px solid #ffffff;
  -webkit-transition: all .15s ease-out;
  transition: all .15s ease-out;

  &.table-featured, &:hover {
    border-color: #5ccdde;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }

  th {
    text-align: center;
    background-color: #5ccdde;
    border-bottom: none;
    color: #ffffff;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  td {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

/* List Group */

.list-group-item {
  border-color: #ebeef2;
}

a.list-group-item {
  &.active {
    background-color: #5ccdde;
    border-color: #5ccdde;

    &:hover, &:focus {
      background-color: #5ccdde;
      border-color: #5ccdde;
    }

    .list-group-item-text, &:hover .list-group-item-text, &:focus .list-group-item-text {
      color: #ffffff;
    }
  }

  &:hover, &:focus {
    background-color: #f9f9f9;
  }

  &.active > .badge {
    background: rgba(0, 0, 0, 0.4);
    color: #ffffff;
  }
}

/* Navs */

.nav-pills {
  > li > a {
    color: #454e59;
    border-radius: 3px;
  }

  i.icon-push {
    margin-right: 8px;
  }

  > li.active > a {
    color: #ffffff;
    background-color: #5ccdde;

    &:hover, &:focus {
      color: #ffffff;
      background-color: #5ccdde;
    }
  }
}

.dropdown-menu > {
  li > a {
    &:hover, &:focus {
      color: #ffffff;
      background-color: #5ccdde;
    }
  }

  .active > a {
    color: #ffffff;
    background-color: #5ccdde;

    &:hover, &:focus {
      color: #ffffff;
      background-color: #5ccdde;
    }
  }
}

.nav {
  .open > a {
    color: #ffffff;
    background-color: #5ccdde;

    &:hover, &:focus {
      color: #ffffff;
      background-color: #5ccdde;
    }
  }

  > li i {
    font-size: 14px;
  }
}

.nav-pills {
  > .active > a > .badge {
    color: #5ccdde;
  }

  &.nav-icons > li.active > a {
    i, &:hover i, &:focus i {
      color: #ffffff;
    }
  }
}

.nav-stacked > li > a {
  margin: 4px 0 0;
}

.nav {
  .caret {
    border-top-color: #5ccdde;
    border-bottom-color: #5ccdde;
  }

  a {
    &:hover .caret, &:focus .caret {
      border-top-color: #5ccdde;
      border-bottom-color: #5ccdde;
    }
  }

  > li > a {
    &:hover, &:focus {
      background-color: #f9f9f9;
    }
  }
}

.nav-tabs {
  border-bottom-color: #ebeef2;

  > li {
    margin-bottom: 0;

    > a {
      padding-left: 7px;
      padding-right: 7px;
      margin-bottom: -1px;
      font-weight: 500;
      color: #454e59;

      &:hover {
        border-color: #ebeef2;
      }
    }

    &.active > a {
      color: #454e59;
      border-color: #ebeef2;
      border-bottom-color: transparent;

      &:hover, &:focus {
        color: #454e59;
        border-color: #ebeef2;
        border-bottom-color: transparent;
      }
    }
  }
}

.nav-pills > li.active > a > .badge {
  background: rgba(0, 0, 0, 0.2);
  color: #ffffff;
}

.nav-users {
  list-style: none;
  margin: 0;
  padding-left: 5px;
}

.nav-users-online, .nav-users-away, .nav-users-offline {
  border-left-width: 2px;
  border-left-style: solid;
}

.nav-users-online {
  border-left-color: #afde5c;
}

.nav-users-away {
  border-left-color: #deb25c;
}

.nav-users-offline {
  border-left-color: #de815c;

  a {
    opacity: .5;
  }
}

.nav-users a {
  display: block;
  min-height: 56px;
  padding: 8px 10px 8px 60px;
  position: relative;
  color: #454e59;
  border-radius: 3px;
  -webkit-transition: all .1s ease-out;
  transition: all .1s ease-out;

  &:hover, &:focus {
    text-decoration: none;
    opacity: 1;
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }

  &:active {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.02);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.02);
  }
}

.nav-users-avatar {
  position: absolute;
  top: 8px;
  left: 10px;
  width: 40px;
  height: 40px;
  border: 3px solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}

.nav-users-indicator {
  position: absolute;
  top: 5px;
  left: 5px;
}

.nav-users-heading {
  display: block;
  margin-top: 2px;
  font-weight: 600;
}

.dropdown-menu {
  min-width: 180px;
  padding: 0;
  font-size: 14px;
  border-color: #dae0e8;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  display: block;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: top left;
  transform-origin: top left;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;

  &.dropdown-menu-right {
    -webkit-transform-origin: top right;
    transform-origin: top right;
  }
}

.dropup .dropdown-menu {
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;

  &.dropdown-menu-right {
    -webkit-transform-origin: bottom right;
    transform-origin: bottom right;
  }
}

.open > .dropdown-menu, .dropdown-menu.open {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.lt-ie10 {
  .dropdown-menu {
    display: none;
  }

  .open > .dropdown-menu, .dropdown-menu.open {
    display: block;
  }
}

.dropdown-menu {
  > li > a {
    padding: 8px 10px;
  }

  i {
    opacity: 0.2;
    line-height: 17px;
  }

  a:hover i {
    opacity: 0.5;
  }

  .divider {
    margin: 3px 0;
    padding: 0 !important;
    background-color: #f0f0f0;
  }
}

li.dropdown-header {
  padding: 8px 10px;
  color: #454e59;
  background-color: #ebeef2;
}

.dropdown-submenu {
  position: relative;

  > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -3px;
    margin-left: 0;
  }

  &:hover > .dropdown-menu {
    display: block;
  }

  &.pull-left {
    float: none;

    > .dropdown-menu {
      left: -100%;
      margin-left: 10px;
    }
  }
}

.collapse.in .dropdown.open .dropdown-menu {
  display: block;
  position: static;
  margin: 0 0 0 15px;
  left: auto;
  top: auto;
}

.pagination > {
  li > {
    a, span {
      font-weight: 500;
      color: #5ccdde;
      margin-left: 5px;
      margin-right: 5px;
      border: none !important;
      border-radius: 3px !important;
      background: transparent;
    }
  }

  .active > {
    a, span, a:hover, span:hover, a:focus, span:focus {
      background-color: #5ccdde;
    }
  }

  .disabled > {
    span {
      background: transparent;

      &:hover, &:focus {
        background: transparent;
      }
    }

    a {
      background: transparent;

      &:hover, &:focus {
        background: transparent;
      }
    }
  }
}

.pager > li > {
  a, span {
    border-color: #ebeef2;
  }

  a:hover {
    background-color: #5ccdde;
    border-color: #5ccdde;
    color: #ffffff;
  }
}

.pagination > li > a:hover {
  background-color: #5ccdde;
  border-color: #5ccdde;
  color: #ffffff;
}

.pager > li.disabled > a:hover {
  border-color: #ebeef2;
}

/* Popover */

.popover {
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.popover-title {
  background: none;
  border: none;
  font-size: 15px;
  font-weight: 600;
  padding-top: 7px;
  padding-bottom: 2px;
}

/* Tooltip */

.tooltip {
  z-index: 1051;

  &.in {
    opacity: 1;
  }
}

.tooltip-inner {
  padding: 4px 6px;
  background-color: #000000;
  color: #ffffff;
}

.tooltip {
  &.top .tooltip-arrow, &.top-left .tooltip-arrow, &.top-right .tooltip-arrow {
    border-top-color: #000000;
  }

  &.right .tooltip-arrow {
    border-right-color: #000000;
  }

  &.left .tooltip-arrow {
    border-left-color: #000000;
  }

  &.bottom .tooltip-arrow, &.bottom-left .tooltip-arrow, &.bottom-right .tooltip-arrow {
    border-bottom-color: #000000;
  }
}

/* Breadcrumps */

.breadcrumb {
  background-color: transparent;

  i {
    font-size: 14px;
  }
}

.breadcrumb-top {
  font-size: 12px;
  text-align: right;
  margin-bottom: 0;
  padding-top: 10px;
  padding-bottom: 7px;
}

/* Progress Bars */

.progress {
  background: rgba(0, 0, 0, 0.1);
  height: 18px;
  line-height: 18px;
  font-weight: 600;
}

.progress-bar {
  height: 18px;
  line-height: 18px;
  font-weight: 600;
}

.progress.progress-mini, .progress-bar.progress-mini, .progress-mini .progress-bar {
  height: 7px;
  line-height: 7px;
}

.progress-bar-danger {
  background-color: #de815c;
}

.progress-bar-warning {
  background-color: #deb25c;
}

.progress-bar-success {
  background-color: #afde5c;
}

.progress-bar-info {
  background-color: #5cafde;
}

.progress-bar-primary {
  background-color: #5ccdde;
}

/* Modals */

.modal-content {
  border: none;
}

.modal-header {
  padding: 17px 15px 15px;
  border-bottom: none;
  background-color: #f9f9f9;

  .nav-tabs {
    margin: 0 -15px -15px;
  }
}

.modal-title {
  font-weight: 300;
}

.modal-body {
  padding: 20px 15px;
}

.modal-footer {
  margin-top: 0;
  padding: 9px 15px 10px;
  border-top: none;
  background-color: #f9f9f9;
}

.modal {
  &.fade .modal-dialog {
    opacity: 0;
    -webkit-transition: all .45s ease-out;
    transition: all .45s ease-out;
    -webkit-transform-origin: top center;
    transform-origin: top center;
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }

  &.in .modal-dialog {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.modal-backdrop.in {
  opacity: .75;
}

/* Buttons */

.btn {
  margin: 1px 0;
  background-color: #ffffff;
  border-radius: 3px;

  &.btn-rounded {
    border-radius: 20px;
  }

  &.btn-square {
    border-radius: 0;
  }
}

.btn-ripple {
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  -webkit-transform: scale(0);
  transform: scale(0);

  &.animate {
    -webkit-animation: ripple 1s ease-out;
    animation: ripple 1s ease-out;
  }
}

.btn {
  .gi, .hi, .si, .fi {
    line-height: 1;
  }

  &.disabled, &[disabled] {
    opacity: .4;
  }
}

fieldset[disabled] .btn {
  opacity: .4;
}

.block-options .btn, .input-group .btn, .modal-content .btn {
  margin-top: 0;
  margin-bottom: 0;
}

.btn-default {
  background-color: #eeeeee;
  border-color: #eeeeee;

  &:hover, &:focus, &:active, &.active {
    background-color: #dddddd;
    border-color: #dddddd;
  }
}

.open .btn-default.dropdown-toggle {
  background-color: #dddddd;
  border-color: #dddddd;
}

.btn-default {
  &.disabled {
    background-color: #eeeeee;
    border-color: #eeeeee;

    &:hover, &:focus, &:active, &.active {
      background-color: #eeeeee;
      border-color: #eeeeee;
    }
  }

  &[disabled] {
    &:hover, &:focus, &:active, &.active {
      background-color: #eeeeee;
      border-color: #eeeeee;
    }
  }
}

fieldset[disabled] .btn-default {
  &:hover, &:focus, &:active, &.active {
    background-color: #eeeeee;
    border-color: #eeeeee;
  }
}

.btn-primary {
  background-color: #5ccdde;
  border-color: #5ccdde;

  &:hover, &:focus, &:active, &.active {
    background-color: #02aac4;
    border-color: #02aac4;
    color: #ffffff !important;
  }
}

.open .btn-primary.dropdown-toggle {
  background-color: #02aac4;
  border-color: #02aac4;
  color: #ffffff !important;
}

.btn-primary {
  &.disabled {
    background-color: #5ccdde;
    border-color: #5ccdde;

    &:hover, &:focus, &:active, &.active {
      background-color: #5ccdde;
      border-color: #5ccdde;
    }
  }

  &[disabled] {
    &:hover, &:focus, &:active, &.active {
      background-color: #5ccdde;
      border-color: #5ccdde;
    }
  }
}

fieldset[disabled] .btn-primary {
  &:hover, &:focus, &:active, &.active {
    background-color: #5ccdde;
    border-color: #5ccdde;
  }
}

.btn-danger {
  background-color: #de815c;
  border-color: #de815c;

  &:hover, &:focus, &:active, &.active {
    background-color: #c43902;
    border-color: #c43902;
    color: #ffffff !important;
  }
}

.open .btn-danger.dropdown-toggle {
  background-color: #c43902;
  border-color: #c43902;
  color: #ffffff !important;
}

.btn-danger {
  &.disabled {
    background-color: #de815c;
    border-color: #de815c;

    &:hover, &:focus, &:active, &.active {
      background-color: #de815c;
      border-color: #de815c;
    }
  }

  &[disabled] {
    &:hover, &:focus, &:active, &.active {
      background-color: #de815c;
      border-color: #de815c;
    }
  }
}

fieldset[disabled] .btn-danger {
  &:hover, &:focus, &:active, &.active {
    background-color: #de815c;
    border-color: #de815c;
  }
}

.btn-warning {
  background-color: #deb25c;
  border-color: #deb25c;

  &:hover, &:focus, &:active, &.active {
    background-color: #de9502;
    border-color: #de9502;
    color: #ffffff !important;
  }
}

.open .btn-warning.dropdown-toggle {
  background-color: #de9502;
  border-color: #de9502;
  color: #ffffff !important;
}

.btn-warning {
  &.disabled {
    background-color: #c48402;
    border-color: #c48402;

    &:hover, &:focus, &:active, &.active {
      background-color: #c48402;
      border-color: #c48402;
    }
  }

  &[disabled] {
    &:hover, &:focus, &:active, &.active {
      background-color: #c48402;
      border-color: #c48402;
    }
  }
}

fieldset[disabled] .btn-warning {
  &:hover, &:focus, &:active, &.active {
    background-color: #c48402;
    border-color: #c48402;
  }
}

.btn-success {
  background-color: #afde5c;
  border-color: #afde5c;

  &:hover, &:focus, &:active, &.active {
    background-color: #7dc402;
    border-color: #7dc402;
    color: #ffffff !important;
  }
}

.open .btn-success.dropdown-toggle {
  background-color: #7dc402;
  border-color: #7dc402;
  color: #ffffff !important;
}

.btn-success {
  &.disabled {
    background-color: #afde5c;
    border-color: #afde5c;

    &:hover, &:focus, &:active, &.active {
      background-color: #afde5c;
      border-color: #afde5c;
    }
  }

  &[disabled] {
    &:hover, &:focus, &:active, &.active {
      background-color: #afde5c;
      border-color: #afde5c;
    }
  }
}

fieldset[disabled] .btn-success {
  &:hover, &:focus, &:active, &.active {
    background-color: #afde5c;
    border-color: #afde5c;
  }
}

.btn-info {
  background-color: #5cafde;
  border-color: #5cafde;

  &:hover, &:focus, &:active, &.active {
    background-color: #027dc4;
    border-color: #027dc4;
    color: #ffffff !important;
  }
}

.open .btn-info.dropdown-toggle {
  background-color: #027dc4;
  border-color: #027dc4;
  color: #ffffff !important;
}

.btn-info {
  &.disabled {
    background-color: #5cafde;
    border-color: #5cafde;

    &:hover, &:focus, &:active, &.active {
      background-color: #5cafde;
      border-color: #5cafde;
    }
  }

  &[disabled] {
    &:hover, &:focus, &:active, &.active {
      background-color: #5cafde;
      border-color: #5cafde;
    }
  }
}

fieldset[disabled] .btn-info {
  &:hover, &:focus, &:active, &.active {
    background-color: #5cafde;
    border-color: #5cafde;
  }
}

.btn-link {
  color: #5ccdde;

  &:hover, &:focus {
    color: #5ccdde;
  }
}

/* Panels */

.panel {
  margin-bottom: 20px;
}

.panel-heading {
  padding: 15px;
}

.panel-title {
  font-size: 14px;
}

.panel-default > .panel-heading {
  background-color: #f9f9f9;
}

.panel-group {
  margin-bottom: 20px;
}

/* Pre, Code */

pre {
  background: #151515;
  overflow: scroll;
}

code {
  border: 1px solid #fad4df;
  margin: 1px 0;
  display: inline-block;
}

.btn code {
  display: inline;
  margin: 0;
}

/* Alerts */

.alert {
  color: #ffffff;
  border-width: 0;
  border-radius: 3px;

  .alert-link {
    color: #000000;
    opacity: .4;
  }
}

.alert-danger {
  background-color: #de815c;
}

.alert-warning {
  background-color: #deb25c;
}

.alert-success {
  background-color: #afde5c;
}

.alert-info {
  background-color: #5cafde;
}

.alert-dismissable .close {
  top: -8px;
  right: -26px;
  color: #000000;
}

.close {
  opacity: .25;
  text-shadow: none;

  &:hover, &:focus {
    opacity: .5;
  }
}

/* Labels, Badges */

.label, .badge {
  font-weight: normal;
  font-size: 90%;
}

.label {
  padding: 1px 4px;
}

.badge {
  background: rgba(0, 0, 0, 0.3);
  padding: 3px 6px;
}

.label-danger {
  background-color: #de815c;

  &[href] {
    &:hover, &:focus {
      background-color: #de815c;
    }
  }
}

.label-warning {
  background-color: #deb25c;

  &[href] {
    &:hover, &:focus {
      background-color: #deb25c;
    }
  }
}

.label-success {
  background-color: #afde5c;

  &[href] {
    &:hover, &:focus {
      background-color: #afde5c;
    }
  }
}

.label-info {
  background-color: #5cafde;

  &[href] {
    &:hover, &:focus {
      background-color: #5cafde;
    }
  }
}

.label-primary {
  background-color: #5ccdde;

  &[href] {
    &:hover, &:focus {
      background-color: #5ccdde;
    }
  }
}

.label-default {
  background-color: #999999;

  &[href] {
    &:hover, &:focus {
      background-color: #999999;
    }
  }
}

.label-danger[href] {
  &:hover, &:focus {
    opacity: .75;
  }
}

.label-warning[href] {
  &:hover, &:focus {
    opacity: .75;
  }
}

.label-success[href] {
  &:hover, &:focus {
    opacity: .75;
  }
}

.label-info[href] {
  &:hover, &:focus {
    opacity: .75;
  }
}

.label-primary[href] {
  &:hover, &:focus {
    opacity: .75;
  }
}

.label-default[href] {
  &:hover, &:focus {
    opacity: .75;
  }
}

/* Carousel */

.carousel-control {
  max-width: 50px;

  &.left, &.right {
    background: none;
    opacity: 0;
    filter: none;
  }
}

.carousel:hover .carousel-control {
  &.left, &.right {
    opacity: 1;
  }
}

.carousel-control {
  &.left:hover, &.right:hover {
    background: rgba(0, 0, 0, 0.2);
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 5;
    display: inline-block;
  }

  i {
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
  }
}

.carousel-indicators {
  bottom: 5px;
}

.carousel-caption {
  bottom: 5px;
  text-shadow: 0 5px 15px #000000;
}

/* Thumbnail */

.img-thumbnail-transparent {
  background-color: rgba(255, 255, 255, 0.25);
  border-color: transparent;
}

.img-thumbnail-avatar {
  width: 69px !important;
  height: 69px !important;
}

.img-thumbnail-avatar-2x {
  width: 133px !important;
  height: 133px !important;
}

/* Bottom Margin */

p, .table, .alert, .carousel {
  margin-bottom: 20px;
}

/* Removing shadows and radius */

.navbar-form, .navbar-collapse {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-control {
  -webkit-box-shadow: none;
  box-shadow: none;

  &:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.has-success .form-control:focus, .has-warning .form-control:focus, .has-error .form-control:focus, .progress, .progress-bar, .panel, .modal-content {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.navbar, .dropdown-menu, .tooltip-inner, .breadcrumb, .modal-content, .panel-group .panel {
  border-radius: 0;
}

/*
=================================================================
(#m07hes) HELPERS
=================================================================
*/

.border-top {
  border-top: 1px solid #eeeeee;
}

.border-right {
  border-right: 1px solid #eeeeee;
}

.border-bottom {
  border-bottom: 1px solid #eeeeee;
}

.border-left {
  border-left: 1px solid #eeeeee;
}

.push {
  margin-bottom: 15px !important;
}

.push-top {
  margin-top: 15px !important;
}

.push-top-bottom {
  margin-top: 40px;
  margin-bottom: 40px;
}

.push-bit {
  margin-bottom: 10px !important;
}

.push-bit-top {
  margin-top: 10px !important;
}

.push-bit-top-bottom {
  margin-top: 10px;
  margin-bottom: 10px;
}

.push-right {
  margin-right: 15px;
}

.push-left {
  margin-left: 15px;
}

.push-right-left {
  margin-left: 10px;
  margin-right: 10px;
}

.push-inner-top-bottom {
  padding-top: 15px;
  padding-bottom: 15px;
}

.overflow-hidden {
  overflow: hidden;
}

.display-none {
  display: none;
}

.remove-margin {
  margin: 0 !important;
}

.remove-margin-bottom {
  margin-bottom: 0 !important;
}

.remove-padding {
  padding: 0 !important;
}

.remove-radius {
  border-radius: 0 !important;
}

.remove-radius-top {
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.remove-radius-bottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.lt-ie10 .hidden-lt-ie10 {
  display: none !important;
}

.visible-lt-ie10 {
  display: none;
}

.lt-ie10 .visible-lt-ie10 {
  display: block;
}

:focus {
  outline: 0 !important;
}

/*
=================================================================
(#m08ths) THEMES
=================================================================
*/

/* Default Color Theme specific colors */

.themed-color {
  color: #5ccdde !important;
}

.themed-background {
  background-color: #5ccdde !important;
}

.themed-color-dark {
  color: #454e59 !important;
}

.themed-background-dark {
  background-color: #454e59 !important;
}

/* Contextual Background Colors */

.themed-background-danger {
  background-color: #de815c !important;
}

.themed-background-warning {
  background-color: #deb25c !important;
}

.themed-background-info {
  background-color: #5cafde !important;
}

.themed-background-success {
  background-color: #afde5c !important;
}

.themed-background-muted {
  background-color: #f9f9f9 !important;
}

/*
=================================================================
(#m09res) RESPONSIVE
=================================================================
*/

/* Small devices, Tablets (>767px) */
@media screen and (min-width: 768px) {
  /* General */

  #login-container {
    width: 380px;
    top: 120px;
    margin-left: -190px;
  }

  #page-content {
    padding: 20px 20px 1px;
  }

  #page-container {
    &.header-fixed-top #page-content {
      padding-top: 70px;
    }

    &.header-fixed-bottom #page-content {
      padding-bottom: 70px;
    }
  }

  #page-content {
    &.inner-sidebar-left, &.inner-sidebar-right {
      position: relative;
    }

    &.inner-sidebar-left {
      padding-left: 240px;
    }

    &.inner-sidebar-right {
      padding-right: 240px;
    }
  }

  #page-content-sidebar {
    margin: 0;
    width: 220px;
    position: absolute;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    border-bottom: none;
  }

  #page-content.inner-sidebar-left #page-content-sidebar {
    left: 0;
    border-right: 1px solid #dae0e8;
  }

  #page-container.sidebar-light #page-content.inner-sidebar-left #page-content-sidebar {
    border-left: 1px solid #dae0e8;
  }

  #page-content.inner-sidebar-right #page-content-sidebar {
    right: 0;
    border-left: 1px solid #dae0e8;
  }

  #page-container {
    &.header-fixed-top #page-content-sidebar {
      top: 50px;
    }

    &.header-fixed-bottom #page-content-sidebar {
      bottom: 50px;
    }
  }

  .content-header, .content-header-media .header-section {
    padding-left: 20px;
    padding-right: 20px;
  }

  .block {
    padding-left: 20px;
    padding-right: 20px;

    &.full {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .modal-body {
    padding-left: 20px;
    padding-right: 20px;
  }

  .block {
    .block-content-full {
      margin: -20px -20px -1px;
    }

    &.full .block-content-full {
      margin: -20px;
    }
  }

  .content-header {
    margin: -20px -20px 20px;
  }

  .block, .widget {
    margin-bottom: 20px;
  }

  .block-title {
    margin-left: -20px;
    margin-right: -20px;
  }

  /* Forms */

  .form-bordered {
    margin-left: -20px;
    margin-right: -20px;

    .form-group {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .form-horizontal.form-bordered .form-group {
    padding-left: 5px;
    padding-right: 5px;
  }

  /* Tabs */

  .nav-tabs > li > a {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 3px;
    margin-right: 3px;
  }

  /* Modal */

  .modal-dialog {
    margin: 60px auto;
  }

  .modal-header, .modal-footer {
    padding-left: 20px;
    padding-right: 20px;
  }

  .modal-header .nav-tabs {
    margin-left: -20px;
    margin-right: -20px;
  }

  /* Error Page */

  #error-container h1 {
    font-size: 96px;
    margin: 50px 0 70px;
  }

  /* Timeline */

  .timeline-list {
    li {
      padding: 20px 0;
    }

    &:after {
      position: absolute;
      display: block;
      width: 4px;
      top: 0;
      left: 134px;
      bottom: 0;
      content: "";
      background-color: #f9f9f9;
      z-index: 1;
    }
  }

  .timeline-icon {
    left: 115px;
    right: auto;
  }

  .timeline-time {
    float: left;
    padding: 0;
    text-align: right;
    width: 100px;
    height: 40px;
    line-height: 40px;
    border-bottom: none;
    margin-bottom: 0;
    background-color: transparent;
  }

  .timeline-content {
    padding-left: 166px;
  }
}

/* Medium devices, Desktops (>991px) */
@media screen and (min-width: 992px) {
  /* Alternative Sidebar */

  #page-container {
    &.sidebar-alt-visible-xs #sidebar-alt {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      right: -220px;
    }

    &.sidebar-alt-visible-lg #sidebar-alt {
      -webkit-transform: translateX(-220px);
      transform: translateX(-220px);
    }

    &.sidebar-alt-visible-xs #sidebar-alt-close {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      right: -30px;
      opacity: 0;
    }

    &.sidebar-alt-visible-lg #sidebar-alt-close {
      -webkit-transform: translateX(-250px);
      transform: translateX(-250px);
      opacity: 1;
    }

    &.sidebar-visible-xs {
      #sidebar {
        width: 0;
        -webkit-transform: none;
        transform: none;
      }

      #main-container {
        -webkit-transform: none;
        transform: none;
      }
    }
  }

  /* Sidebar Reset Small Screens Classes */

  /* Layout Transitions */

  #sidebar {
    -webkit-transition: background-color .2s ease-out;
    transition: background-color .2s ease-out;
  }

  #main-container {
    -webkit-transition: none;
    transition: none;
  }

  header.navbar {
    &.navbar-fixed-top, &.navbar-fixed-bottom {
      -webkit-transition: background .2s ease-out;
      transition: background .2s ease-out;
    }
  }

  #page-container {
    &.sidebar-visible-lg-mini #sidebar-toggle-full, &.sidebar-visible-lg-full #sidebar-toggle-mini {
      display: inline-block;
    }

    &.sidebar-visible-lg-mini #sidebar-toggle-mini, &.sidebar-visible-lg-full #sidebar-toggle-full {
      display: none;
    }
  }

  /* Sidebar + Static Layout */

  #sidebar {
    width: 0;
    left: 0;
  }

  #main-container {
    margin-left: 0;
    margin-right: 0;
  }

  #page-container {
    &.sidebar-visible-lg-mini {
      #sidebar {
        width: 40px;
      }

      #main-container {
        margin-left: 40px;
      }
    }

    &.sidebar-visible-lg-full {
      #sidebar {
        width: 220px;
      }

      #main-container {
        margin-left: 220px;
      }
    }

    &.header-fixed-top #sidebar, &.header-fixed-bottom #sidebar {
      position: fixed;
      top: 0;
      bottom: 0;
    }

    &.header-fixed-top #sidebar-brand, &.header-fixed-bottom #sidebar-brand {
      display: block;
    }

    &.sidebar-visible-lg-mini header {
      &.navbar-fixed-top, &.navbar-fixed-bottom {
        left: 40px;
      }
    }

    &.sidebar-visible-lg-full header {
      &.navbar-fixed-top, &.navbar-fixed-bottom {
        left: 220px;
      }
    }

    &.sidebar-visible-lg-mini {
      #sidebar {
        overflow: visible;

        .sidebar-content {
          width: 40px;
        }

        .sidebar-nav-mini-hide {
          display: none;
        }

        .sidebar-nav {
          li {
            position: relative;
          }

          .sidebar-nav-menu + ul {
            position: absolute;
            width: 220px;
            top: 0;
            right: -220px;
            margin: 0;
            padding: 6px 5px;
            background-color: #363e46;
          }
        }
      }

      &.sidebar-light #sidebar .sidebar-nav .sidebar-nav-menu + ul {
        background-color: #e6e6e6;
      }

      #sidebar .sidebar-nav {
        ul {
          a {
            margin: 0;
            padding-left: 10px;
            border-left: none;
          }

          ul {
            margin-left: 0;
          }
        }

        > li.active > ul {
          display: none;
        }

        .sidebar-nav-menu.open + ul {
          display: block !important;
        }
      }
    }
  }

  /* Sidebar + Fixed Layout */

  /* Sidebar Condensed Menu */

  /* Navbar Nav Search */

  .navbar-form-custom {
    width: 300px;
  }

  /* Chat */

  .chatui.chatui-window.widget {
    right: 70px;
    margin-right: 0;
  }
}